import React from "react";
import "../styles/footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div>Designed with Figma, built with React and deployed on Github Pages by Lyall Beveridge</div>
      <div>© 2024 All Rights Reserved</div>
    </div>
  );
}
