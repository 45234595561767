import React from 'react';
import '../styles/titleSection.css';

export default function TitleASCII() {
    return (
        <pre id='page-title'>{`
██╗  ██╗   ██╗ █████╗ ██╗     ██╗         ██████╗ ███████╗██╗   ██╗███████╗██████╗ ██╗██████╗  ██████╗ ███████╗
██║  ╚██╗ ██╔╝██╔══██╗██║     ██║         ██╔══██╗██╔════╝██║   ██║██╔════╝██╔══██╗██║██╔══██╗██╔════╝ ██╔════╝
██║   ╚████╔╝ ███████║██║     ██║         ██████╔╝█████╗  ██║   ██║█████╗  ██████╔╝██║██║  ██║██║  ███╗█████╗
██║    ╚██╔╝  ██╔══██║██║     ██║         ██╔══██╗██╔══╝  ╚██╗ ██╔╝██╔══╝  ██╔══██╗██║██║  ██║██║   ██║██╔══╝
███████╗██║   ██║  ██║███████╗███████╗    ██████╔╝███████╗ ╚████╔╝ ███████╗██║  ██║██║██████╔╝╚██████╔╝███████╗
╚══════╝╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝    ╚═════╝ ╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═╝╚═╝╚═════╝  ╚═════╝ ╚══════╝
        `}
        </pre>
    );
}

